<template>
<div>
     <div class="relative flex flex-col items-start mt-5">
        <div class="w-52">
            <div 
                class="flex p-1 my-2 bg-white border rounded-sm cursor-pointer border-secondary" @click="activeDrop = !activeDrop"
            >
                <div class="flex flex-wrap flex-auto"></div>
                <span 
                    class="flex items-center w-full p-1 px-2 text-sm text-left text-secondary"
                >
                    {{activeTitle}}
                </span>
                <div class="flex items-center w-8 py-1 pl-2 pr-1 text-gray-300 border-l border-gray-200">
                    <button class="w-6 h-6 text-gray-600 outline-none cursor-pointer focus:outline-none">
                        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-4 h-4 feather feather-chevron-up" :class="{'transY':activeDrop}">
                            <polyline points="18 15 12 9 6 15"></polyline>
                        </svg>
                    </button>
                </div>
            </div>
        </div>
        <div 
            class="absolute z-40 hidden overflow-y-auto rounded-sm shadow top-100 w-52 lef-0 max-h-select" 
            :class="{'activeDrop' : activeDrop }"
        >
            <div class="flex flex-col w-full">
                <div class="w-full bg-white border-b rounded-t ">
                    <div 
                        v-for="(item, index) in dtDrop" :key="index"
                        class="flex items-center w-full p-2 pl-2 mb-1 cursor-pointer hover:bg-grays-100"
                        @click=handleSelect(item)
                        v-if="activeTitle !== item.name"
                    >
                        <div class="flex items-center w-full capitalize" >
                            <div class="mx-2 text-sm ">
                                <span v-if="lang == 'id'">{{item.name}}</span>
                                <span v-else>{{item.name_eng}}</span>
                            </div>
                        </div>
                    </div>

                    <div 
                        class="flex items-center w-full p-2 pl-2 mb-1 cursor-pointer hover:bg-grays-100"
                        @click=handleSelectAll()
                        v-if="activeTitle !== 'Tampilkan Semua' && activeTitle !== 'Select All'"
                    >
                        <div class="flex items-center w-full capitalize" >
                            <div class="mx-2 text-sm ">
                                <span v-if="lang == 'id'">Tampilkan Semua</span>
                                <span v-else>Select All</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="flex flex-wrap justify-between mt-5">
        <div class="lg:flex shadow-lg mb-8  w-full md:w-47%"  v-for="(item, index) in dtCard" :key="index">
            <div 
                class="flex-none h-48 overflow-hidden text-center bg-center bg-no-repeat bg-contain rounded-t lg:h-auto lg:w-40 cursorZom lg:rounded-t-none lg:rounded-l" :style="{ backgroundImage: `url(${item.image})` }"
                @click="showModal(item.image)"
            >
            </div>

            <div class="flex flex-col justify-between w-full p-4 leading-normal text-left bg-grays-100">
                <h1 class="text-xl font-bold text-gray-900 h-14">
                    <span v-if="lang == 'id'">{{item.name}}</span>
                    <span v-else>{{item.name_eng}}</span>
                </h1>
                <p class="mt-3 text-xs text-gray-600"> {{ $t('product.title2') }}</p>
                <p class="mt-2 font-bold text-gray-900 md:text-lg">{{item.produce_by}}</p>
                
            </div>
        </div>
    </div>

    <transition name="fade">
      
        <div class="fixed inset-0 z-50 flex w-full min-h-full bglac place-items-center" v-if="modal"
            @click="onToggle"
        >
            <div
                class="relative w-full max-w-xl p-3 mx-auto my-auto bg-white bg-center bg-no-repeat bg-contain rounded-sm shadow-lg h-96"
                :style="{ backgroundImage: `url(${imgActive})` }"
            >
                
            </div>
        </div>

    </transition>
       

</div>
</template>

<script>
export default {
    props:{
        dtCard: {
            type: Array
        },
        lang:{
            type: String
        },
        dtDrop: {
            type: Array
        },
        dType:{
            type: Number
        },
        tabActive:{
            type: Number
        }


    },
    data() {
        return {
            activeDrop:false,
            activeTitle: 'Select Category',
            imgActive:false,
            modal:false
        }
    },
    
    methods: {
        showModal(image){
            this.imgActive = image
            this.modal = true
        },
        onToggle() {
            this.modal = !this.modal;
            this.imgActive = ''
        },
        async handleSelect(item){
            if(this.lang == 'id'){
                this.activeTitle = item.name
            }else{
                this.activeTitle = item.name_eng
            }
            this.activeDrop = false
          
            try {
                const query = {
                    query: `&search[product_type.name]=${item.name}`,
                    type:this.dType,
                }
                
                await this.$store.dispatch('produk/getDtProd', query)
				
            } catch (error) {
                console.log(error)
            }


        },
        async handleSelectAll(){
            if(this.lang == 'id'){
                this.activeTitle = 'Tampilkan Semua'
            }else{
                this.activeTitle = 'Select All'
            }
            this.activeDrop = false


            try {
                const query = {
                    type:this.dType
                }
                
                await this.$store.dispatch('produk/getDtProd', query)
				
            } catch (error) {
                console.log(error)
            }
        }
    },
    
    watch: {
        tabActive(val){
            if(val){
               this.activeTitle = 'Select Category'
            }
        }
    },
}
</script>

<style scoped>
    .top-100 {top: 100%}
    .bottom-100 {bottom: 100%}
    .max-h-select {
        max-height: 300px;
    }

    .activeDrop{
        display: block;
    }

    .transY{
        transform: rotate(180deg);
    }

    .fade-enter {
        opacity: 0;
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 300ms ease-out;
    }

    .fade-leave-to {
        opacity: 0;
    }

    .bglac{
        background-color:rgba(0, 0, 0, 0.597);
    }

    .cursorZom{
        cursor: zoom-in;
    }
</style>